






















import vue from 'vue';
import VideoComponent from "@/components/shared/VideoComponent.vue";

export default vue.extend ({
    components: {
        VideoComponent
    },
    computed: {
        sectionToUse() {
            const res = this.$store.getters.getPageOptions("StreamVideoLive");
    
            return res.components;
        }
    }
})
